import axios from '@/requests/axios';

const API_URL_EVALUATE = '/evaluate';

const API_URL_TEXTURE = API_URL_EVALUATE + '/texture';
const API_URL_TASTE = API_URL_EVALUATE + '/taste';
const API_URL_BRANDNAME = API_URL_EVALUATE + '/brandname';

export const EvaluationRequest = {
  evaluateTexture(params) {
    return axios.post(API_URL_TEXTURE, {
      inputs: [params]
    });
  },
  evaluateTaste(params) {
    return axios.post(API_URL_TASTE, {
      inputs: [params]
    });
  },
  evaluateBrandname(params) {
    return axios.post(API_URL_BRANDNAME, {
      inputs: [params]
    });
  }
};
