import { scales as SCALES_DICTIONARY } from '@/data/scales.json';
import { commonClassNumber as NAME_CLASSES_NUMBER_COMMON } from '@/data/classes.json';
import { NameClassUtil } from '@/util/NameClassUtil';

export const GraphScaleUtil = {
  /**
   * scaleIdに合致するkeyが持つvalueを返す.
   *
   * @param {Number} nameClassNumber
   * @returns {Object} {number: number, name: string, scales: [{id: number, name: string, label: string}]}.
   */
  getScale(scaleId) {
    const num = Number(scaleId);
    return SCALES_DICTIONARY.find(scale => {
      return scale.id === num;
    });
  },
  /**
   * Chart用のラベルを生成する
   *
   * @param {String} name
   * @param {Number} nameClassNumber
   */
  generateChartLabel(name, nameClassNumber) {
    if (Number(nameClassNumber) === NAME_CLASSES_NUMBER_COMMON) {
      return name;
    }
    return name + '（' + NameClassUtil.getNameClass(nameClassNumber).name + '）';
  },
  /**
   * nameClassNumberに合致するnameClassのscalesのlabelをid昇順の配列で返す.
   *
   * @param {Number} nameClassNumber
   */
  getNameClassSortedScaleLabels(nameClassNumber) {
    const nameClassSortedScaleLabels = [];
    // 描画用データの尺度ラベルの表現を変える // TODO: API側に実装
    this.getNameClassSortedScales(nameClassNumber).forEach(nameClassScaleNumber => {
      const label = this.getScale(nameClassScaleNumber).label;
      // reverseフラグのtrue/falseに応じてラベルを反転
      if (this.getScale(nameClassScaleNumber).reverse) {
        nameClassSortedScaleLabels.push(label.split('-')[1] + '-' + label.split('-')[0]);
      } else {
        nameClassSortedScaleLabels.push(label);
      }
    });
    return nameClassSortedScaleLabels;
  },
  /**
   * nameClassNumberに合致するnameClassのscalesをid昇順の配列で返す.
   *
   * @param {Number} nameClassNumber
   */
  getNameClassSortedScales(nameClassNumber) {
    const nameClassScales = NameClassUtil.getNameClass(nameClassNumber).scales;
    return nameClassScales.sort(function(a, b) {
      // 昇順ソート
      if( a < b ) return -1;
      if( a > b ) return 1;
      return 0;
    });
  },
  /**
   * nameClassNumberに合致するnameClassのscaleのうち,
   * scaleIndex番目のlabelのpositive/negativeに合わせた部分を切り出して返す.
   *
   * @param {Number} nameClassNumber
   * @param {Number} scaleIndex
   * @param {Boolean} isPositiveValue
   */
  getNameClassSortedScaleSignedLabel(nameClassNumber, scaleIndex, isPositiveValue) {
    const labels = this.getNameClassSortedScaleLabels(nameClassNumber);
    if(isPositiveValue){
      // label右辺のPositiveLabelの部分を返す
      return labels[scaleIndex].split('-')[1];
    }else{
      // label左辺のNegativeLabelの部分を返す
      return labels[scaleIndex].split('-')[0];
    }
  },
  /**
   * グラフを見やすくするため、scales.json の reverse の設定に合わせ、評価値を反転させる.
   *
   * @param {*} scales 反転処理を通したい評価リスト
   */
  reverseScales(scales) {
    let reversedScales = [];
    // reversedScalesは必要な部分を反転した評価・・・nameFromとnameToを反転、calcResultの符号を反転
    // TODO: APIの仕様が変わると変更が必要. API側で処理させて、フロントでは考えなくていいようにしたい.
    scales.forEach( scale => {
      if (this.getScale(scale.id).reverse) {
        reversedScales.push({
          id: scale.id,
          nameFrom: scale.nameTo,
          nameTo: scale.nameFrom,
          calcResult: String(-scale.calcResult)
        });
      } else {
        reversedScales.push({
          id: scale.id,
          nameFrom: scale.nameFrom,
          nameTo: scale.nameTo,
          calcResult: scale.calcResult
        });
      }
    });
    return reversedScales;
  },
  /**
   * scalesから、nameClassNumberで指定したnameClassで表示すべき尺度だけを抽出する.
   *
   * @param {Array} scales
   * @param {Number} nameClassNumber
   */
  filterScales(scales, nameClassNumber) {
    const scaleIds = NameClassUtil.getNameClass(nameClassNumber).scales;
    return scales.filter(scale => {
      return scaleIds.indexOf(scale.id) >= 0;
    });
  }
};
