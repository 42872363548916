import React, { useState, useMemo } from 'react';
import { EvaluationRequest } from '@/requests/evaluation/EvaluationRequest';
import { StringValidator } from '@/validators/StringValidator';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import { Container, Row, Col } from 'react-bootstrap';
import { Tabs, Tab } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import { Breadcrumb, ButtonToolbar, Button, Spinner } from 'react-bootstrap';
import 'chartjs-plugin-datalabels';

import './Evaluation.scss';

import * as mutations from './graphql/mutations';
import UUID_V1 from 'uuid/v1';
import { API, graphqlOperation } from 'aws-amplify';

// utils
import { GraphScaleUtil } from '@/util/GraphScaleUtil';
import { NameClassUtil } from '@/util/NameClassUtil';
import { CsvUtil } from '@/util/CsvUtil';
import { ExcelUtil } from '@/util/ExcelUtil';
import { DateTimeUtil } from '@/util/DateTimeUtil';

// components
import EvaluationFAQ, { FAQ_TAB_KEY } from '@/components/organisms/evaluation/tab-contents/EvaluationFAQ';
import EvaluationHistory, { HISTORY_TAB_KEY } from '@/components/organisms/evaluation/tab-contents/EvaluationHistory';
import NameFormSubmitModal from '@/components/molecules/evaluation/NameFormSubmitModal';
import EvaluationBarChart from '@/components/molecules/evaluation/EvaluationBarChart';

// data files
import { classes as NAME_CLASSES_DICTIONARY, commonClassNumber as NAME_CLASSES_NUMBER_COMMON } from './data/classes.json';
import logo_IE_Naming from './images/logo/ImpressionEvaluator/Naming/logo_transparent.png';
import { Promise } from 'q';

export const NAME_FORM_INPUT_TITLE = '商品名／サービス名（読み）';
export const NAME_FORM_SELECT_TITLE = '商品／サービスの分類';
export const NAME_CHART_COMMON_TITLE = '感性評価結果（共通）';
export const NAME_CHART_UNIQUE_TITLE = '感性評価結果（分類別特性）';

// TODO: コンポーネント分割時に移動する
const EVALUATION_TAB_KEY = 'evaluation';

// ####################
// 評価
// ####################

/**
 * 文字列から想起される感性の評価値をリストで返す.
 *
 * @param {String} value 評価したい文字列
 * @returns {Array} evaluatedScales
 */
async function calcEvaluatedScales(value) {
  let evaluatedScales = [];

  // TODO: 取得したいScaleによって、不要なAPIは呼ばなくてもいいようにする.
  // TODO: いずれはAPI側でscaleのidで必要な要素だけ応答するようにする.
  const [resTexture, resTaste, resBrandname] = await Promise.all([
    EvaluationRequest.evaluateTexture(value),
    EvaluationRequest.evaluateTaste(value),
    EvaluationRequest.evaluateBrandname(value)
  ]);

  evaluatedScales = evaluatedScales.concat(
    resTexture.data.scaleOutputsList[0].scaleOutputList,
    resTaste.data.scaleOutputsList[0].scaleOutputList,
    resBrandname.data.scaleOutputsList[0].scaleOutputList
  );
  return evaluatedScales;
}

/**
 * 検索履歴の保存
 *
 * @param {*} userId
 * @param {*} name
 * @param {*} nameClassNumber
 * @param {*} sortedCommonScales
 * @param {*} sortedUniqueScales
 */
function createEvaluationHistory(userId, name, nameClassNumber, sortedCommonScales, sortedUniqueScales) {
  // charts data
  const charts = [];
  // common chart
  const commonChartDataSets = [];
  sortedCommonScales.forEach(element => {
    commonChartDataSets.push({
      scale: GraphScaleUtil.getScale(element.id).label,
      value: element.calcResult
    });
  });
  const commonChartDataInput = {
    classNumber: NAME_CLASSES_NUMBER_COMMON,
    class: NameClassUtil.getNameClass(NAME_CLASSES_NUMBER_COMMON).name,
    dataSets: commonChartDataSets
  };
  charts.push(commonChartDataInput);
  // unique chart
  if(sortedUniqueScales.length !== 0){
    const uniqueChartDataSets = [];
    sortedUniqueScales.forEach(element => {
      uniqueChartDataSets.push({
        scale: GraphScaleUtil.getScale(element.id).label,
        value: element.calcResult
      });
    });
    const uniqueChartDataInput = {
      classNumber: nameClassNumber,
      class: NameClassUtil.getNameClass(nameClassNumber).name,
      dataSets: uniqueChartDataSets
    };
    charts.push(uniqueChartDataInput);
  }
  // create input data
  const createEvaluationHistoryInput = {
    id: UUID_V1(),
    userId: userId,
    dateTime: DateTimeUtil.getCurrentTimeInJST(),
    name: name,
    charts: charts,
  };

  // mutation
  API.graphql(graphqlOperation(mutations.createEvaluationHistory, {input: createEvaluationHistoryInput}));
}

/**
 * scalesを、Chartライブラリで使用するDataSetsの形に変換する.
 *
 * @param {String} name 商品名／サービス名
 * @param {Number} nameClassNumber 商品名／サービス名の分類番号
 * @param {Array} sortedScales 商品名／サービス名の評価結果（分類で仕分け済み）
 */
function scalesToChartDataSet(name, nameClassNumber, sortedScales) {
  // TODO: 色指定とか引数でできるようにする
  const chartDataSet = {
    label: GraphScaleUtil.generateChartLabel(name, nameClassNumber),
    backgroundColor: 'rgba(255,99,132,0.2)',
    borderColor: 'rgba(255,99,132,0.5)',
    borderWidth: 1,
    hoverBackgroundColor: 'rgba(255,99,132,0.4)',
    hoverBorderColor: 'rgba(255,99,132,1)',
    data: sortedScales.map(scale => scale.calcResult)
  };

  return chartDataSet;
}

// ####################
// HTML
// ####################
export default function Evaluation() {

  // 定数定義
  const SAMPLE_NAME_HAPIHAPI = 'はぴはぴ';
  const NAME_CHART_COMMON_ID = 'name-chart-common';
  const NAME_CHART_UNIQUE_ID = 'name-chart-unique';

  // state定義
  // active-tab
  const [activeTabKey, setActiveTabKey] = useState(EVALUATION_TAB_KEY);
  // name-form-input
  const [nameFormInputName, setNameFormInputName] = useState('');
  // name-form-select
  const [nameFormSelectedNameClassNumber, setNameFormSelectedNameClassNumber] = useState(NAME_CLASSES_NUMBER_COMMON);
  // name-form-submit
  const [isShowNameFormSubmitModal, setIsShowNameFormSubmitModal] = useState(false);
  const [isEvaluating, setIsEvaluating] = useState(false);
  // name-chart-common
  const nameChartCommonClassNumber = NAME_CLASSES_NUMBER_COMMON;
  const [nameChartCommonDataSets, setNameChartCommonDataSets] = useState([]);
  // name-chart-unique
  const [nameChartUniqueClassNumber, setNameChartUniqueClassNumber] = useState(NAME_CLASSES_NUMBER_COMMON);
  const [nameChartUniqueDataSets, setNameChartUniqueDataSets] = useState([]);

  // evaluation result
  const [evaluatedName, setEvaluatedName] = useState(null);
  const [evaluatedCommonScales, setEvaluatedCommonScales] = useState([]);
  const [evaluatedUniqueScales, setEvaluatedUniqueScales] = useState([]);

  // download button state
  const isCommonDownloadDisabled = useMemo(() => {
    return evaluatedCommonScales.length === 0;
  }, [evaluatedCommonScales]);
  const isUniqueDownloadDisabled = useMemo(() => {
    return evaluatedUniqueScales.length === 0;
  }, [evaluatedUniqueScales]);

  // render: レンダリングパフォーマンス改善のためにメモ化
  // name-chart-common
  const nameChartCommon = useMemo(() => {
    return (
      <EvaluationBarChart
        chartId={NAME_CHART_COMMON_ID}
        dataSets={nameChartCommonDataSets}
        nameClassNumber={nameChartCommonClassNumber}/>
    );
  }, [nameChartCommonDataSets, nameChartCommonClassNumber]);
  // name-chart-unique
  const nameChartUnique = useMemo(() => {
    return (
      <EvaluationBarChart
        chartId={NAME_CHART_UNIQUE_ID}
        dataSets={nameChartUniqueDataSets}
        nameClassNumber={nameChartUniqueClassNumber}/>
    );
  }, [nameChartUniqueDataSets, nameChartUniqueClassNumber]);

  // ####################
  // Handler
  // ####################

  // button "評価"
  /**
   *
   *
   * @param {String} name 評価したい商品名/サービス名
   * @param {String} nameClassNumber 評価したい商品名/サービス名の分類番号
   * @param {boolean} isSample
   */
  async function evaluateHandler(name, nameClassNumber, isSample) {
    // 評価中はボタンを非アクティブ化する
    setIsEvaluating(true);

    // 動作が停止しないようにバリデーションをかける.
    if(!StringValidator.isKana(name)){
      setIsEvaluating(false);
      return;
    }

    // TODO: nameClassNumber == NAME_CLASSES_NUMBER_COMMON の場合、nameChartUniqueを非表示にする

    // ↓データ取得、抽出
    // nameをAPIに送り、応答を取得する
    const evaluatedScales = await calcEvaluatedScales(name);
    // グラフを見やすくするため、scalesの評価値を反転させる. 別途Labelも反転させる必要あり. // TODO: API側に実装
    const evaluatedReversedScales = GraphScaleUtil.reverseScales(evaluatedScales);
    // 応答の尺度から、nameClassの尺度を抽出する // TODO: API側に実装 -> calcEvaluatedScales関数内に実装したい
    const sortedCommonScales = GraphScaleUtil.filterScales(evaluatedReversedScales, NAME_CLASSES_NUMBER_COMMON);
    const sortedUniqueScales = Number(nameClassNumber) === NAME_CLASSES_NUMBER_COMMON ? [] : GraphScaleUtil.filterScales(evaluatedReversedScales, nameClassNumber);

    // ↓データが抽出仕切ったので、評価履歴を保存する
    // 評価例での利用か、実際の評価かによってuserIdを切り替える.
    // TODO: "userId"を実際のユーザー名にする.
    const userId = isSample ? 'sample' : 'userId';
    // saveEvaluatedHistories(userId, name, nameClass, commonDataSets, uniqueDataSets);
    // TODO: 今の所は評価例は履歴に残さない.が、取り扱いが決まったら残すようにするかも.
    if(!isSample){
      createEvaluationHistory(userId, name, nameClassNumber, sortedCommonScales, sortedUniqueScales);
    }

    // ↓データが抽出しきったので、グラフ描画のためのデータ整理
    // 抽出した尺度からグラフ描画用データを作成し、グラフ描画用stateに代入する（表示するDataSetsは1つなので、そのまま代入する）
    setNameChartCommonDataSets([scalesToChartDataSet(name, NAME_CLASSES_NUMBER_COMMON, sortedCommonScales)]);
    setNameChartUniqueDataSets([scalesToChartDataSet(name, nameClassNumber, sortedUniqueScales)]);

    // ファイル出力のために評価結果を保持
    setEvaluatedName(name);
    setNameChartUniqueClassNumber(nameClassNumber);
    setEvaluatedCommonScales(sortedCommonScales);
    setEvaluatedUniqueScales(sortedUniqueScales);

    // 評価中はボタンを非アクティブ化する -> 評価終了後はアクティブ化
    setIsEvaluating(false);
  }

  // button "感性評価結果ダウンロード"
  //TODO: 未評価や分類なしのときは、ダウンロードボタンを無効にする
  function canvasDownloadHandler(id) {
    const canvas = document.getElementById(id);
    const a = document.createElement('a');
    a.download = 'chart.jpg';
    a.href = canvas.toDataURL();
    a.click();
  }

  /**
   * CSVダウンロード
   */
  const csvDownloadHandler = (nameClassNumber) => {
    const fileName = GraphScaleUtil.generateChartLabel(evaluatedName, nameClassNumber);
    const fields = GraphScaleUtil.getNameClassSortedScaleLabels(nameClassNumber);
    const data = Number(nameClassNumber) === NAME_CLASSES_NUMBER_COMMON ?
      evaluatedCommonScales.map(scale => scale.calcResult) : evaluatedUniqueScales.map(scale => scale.calcResult);

    // 分類を追加
    fields.unshift('分類');
    data.unshift(Number(nameClassNumber) === NAME_CLASSES_NUMBER_COMMON ?
      '共通' : NameClassUtil.getNameClass(nameClassNumber).name);
    // 商品別/サービス名を追加
    fields.unshift('商品名/サービス名');
    data.unshift(evaluatedName);

    const aTag = CsvUtil.createCsvDownloadLink(fileName, fields, data);
    aTag.click();
  };

  /**
   * Excelダウンロード
   */
  const excelDownloadHandler = (nameClassNumber) => {
    const fileName = GraphScaleUtil.generateChartLabel(evaluatedName, nameClassNumber);
    const headers = GraphScaleUtil.getNameClassSortedScaleLabels(nameClassNumber);
    const data = Number(nameClassNumber) === NAME_CLASSES_NUMBER_COMMON ?
      evaluatedCommonScales.map(scale => scale.calcResult) : evaluatedUniqueScales.map(scale => scale.calcResult);

    // 分類を追加
    headers.unshift('分類');
    data.unshift(Number(nameClassNumber) === NAME_CLASSES_NUMBER_COMMON ?
      '共通' : NameClassUtil.getNameClass(nameClassNumber).name);
    // 商品別/サービス名を追加
    headers.unshift('商品名/サービス名');
    data.unshift(evaluatedName);

    const aTag = ExcelUtil.createExcelDownloadLink(fileName, headers, [data]);
    aTag.click();
  };

  // button "ひとこと評価コピー"
  // button "ひとこと評価ダウンロード"

  // ####################
  // HTML
  // ####################
  return (
    <div className="Evaluation">
      <Breadcrumb>
        {/* react-router-dom で動作させるため、全Itemをactive（disable links）にする */}
        <Breadcrumb.Item active><Link to="/">Hapina</Link></Breadcrumb.Item>
        <Breadcrumb.Item active><Link to={{pathname: '/', hash:'#ImpressionEvaluator'}}>KANSEI - Impression Evaluator</Link></Breadcrumb.Item>
        <Breadcrumb.Item active>Naming</Breadcrumb.Item>
      </Breadcrumb>
      <ReactTooltip effect="solid"/>
      <Container className="p-3">
        {/* row title */}
        <Row>
          <Col xs={12} className="text-center">
            {/* <h1 className="service-title pb-0">KANSEI - Impression Evaluator</h1>
            <h5 className="service-title pt-0">with Hapina (ハピナ) - 感性評価AI</h5> */}
            <img src={logo_IE_Naming} className="logo-service" alt="logo_IE_Naming" />
          </Col>
        </Row>
        {/* /row title */}
        {/* row nav-tab */}
        <Row>
          <Col xs={12}>
            <Tabs activeKey={activeTabKey} onSelect={k => setActiveTabKey(k)} className="mb-3">
              <Tab eventKey={EVALUATION_TAB_KEY} title="名前評価">
                <div className="p-3">
                  {/* row tab-pane-title */}
                  <div className="row mb-3">
                    <div className="col-12">
                      <h4 className="title">名前の感性評価</h4>
                      <p>モノの名前が人にどのような印象を与えるかを評価します。商品名やサービス名の読みから想起される印象を数値化してグラフに表します。</p>
                      <p>複数の単語からできている名前は、単語で分けて評価してください。（例：感性AIの場合「かんせい／えーあい」と分ける）</p>
                    </div>
                  </div>
                  { /* 評価条件フォーム */ }
                  <Form onSubmit={e => e.preventDefault()}>
                    <Form.Row className="mb-3">
                      <Form.Group as={Col} md={6} controlId="evaluationName" className="mb-2">
                        <Form.Label className="title-sub mb-0">
                          【{NAME_FORM_INPUT_TITLE}】
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={nameFormInputName}
                          placeholder="ひらがな または カタカナ で入力"
                          onChange={(e) => setNameFormInputName(e.target.value)}
                          disabled={isEvaluating} autoComplete="off"/>
                      </Form.Group>
                      <Form.Group as={Col} md={6} controlId="evaluationNameClass" className="mb-2">
                        <Form.Label className="title-sub mb-0">
                        【{NAME_FORM_SELECT_TITLE}】
                        </Form.Label>
                        <Form.Control
                          as="select"
                          value={nameFormSelectedNameClassNumber}
                          onChange={(e) => setNameFormSelectedNameClassNumber(Number(e.target.value))}
                          disabled={isEvaluating}>
                          {
                            NAME_CLASSES_DICTIONARY.map((clazz) => {
                              return <option key={clazz.number} value={clazz.number}>{clazz.name}</option>;
                            })
                          }
                        </Form.Control>
                      </Form.Group>
                      <Col xs={12} className="mb-2">
                        <button
                          type="button"
                          id="name-form-sample"
                          className="btn btn-outline-info btn-block font-weight-bold"
                          onClick={() => evaluateHandler(SAMPLE_NAME_HAPIHAPI, nameFormSelectedNameClassNumber, true)} disabled={isEvaluating}>
                            名前【{SAMPLE_NAME_HAPIHAPI}】, 分類【{NameClassUtil.getNameClass(nameFormSelectedNameClassNumber).name}】 の評価例を見る
                        </button>
                      </Col>
                      <Col xs={12}>
                        {/* 評価ボタン押下時には、確認モーダルを出す */}
                        <ButtonToolbar>
                          <Button id="name-form-submit" variant="outline-primary" block={true} disabled={isEvaluating} className="font-weight-bold" onClick={() => setIsShowNameFormSubmitModal(true)}>
                            {isEvaluating ? <Spinner animation="border" size="sm" role="status" className="my-1 mr-2" aria-hidden="true"/> : null}
                            {isEvaluating ? '評価中…'  : '評価'}
                          </Button>
                          <NameFormSubmitModal
                            show={isShowNameFormSubmitModal}
                            name={nameFormInputName}
                            nameclassnumber={nameFormSelectedNameClassNumber}
                            onCancel={() => setIsShowNameFormSubmitModal(false)}
                            onSubmit={() => {
                              setIsShowNameFormSubmitModal(false);
                              evaluateHandler(nameFormInputName, nameFormSelectedNameClassNumber, false);
                            }}
                          />
                        </ButtonToolbar>
                      </Col>
                    </Form.Row>
                  </Form>
                  {/* 評価結果チャート */}
                  <Row>
                    {/* 共通印象評価結果 */}
                    <Col md={6}>
                      <Row>
                        <Col xs={9}>
                          <h6 className="title-sub">【{NAME_CHART_COMMON_TITLE}】</h6>
                        </Col>
                        <Col xs={3}>
                          <img
                            src="https://unicons.iconscout.com/release/v1.0.0/svg/table.svg"
                            className={'figure-img img-icon-square select-none ' + (isCommonDownloadDisabled ? 'img-btn-disabled' : 'img-btn')}
                            alt="Excelダウンロード"
                            data-tip="Excelダウンロード"
                            onClick={(e) => !isCommonDownloadDisabled && excelDownloadHandler(NAME_CLASSES_NUMBER_COMMON)}/>
                          <img
                            src="https://unicons.iconscout.com/release/v1.0.0/svg/file-download-alt.svg"
                            className={'figure-img img-icon-square select-none ' + (isCommonDownloadDisabled ? 'img-btn-disabled' : 'img-btn')}
                            alt="CSVダウンロード"
                            data-tip="CSVダウンロード"
                            onClick={(e) => !isCommonDownloadDisabled && csvDownloadHandler(NAME_CLASSES_NUMBER_COMMON)}/>
                          <img
                            id="name-chart-common-download"
                            src="https://unicons.iconscout.com/release/v1.0.0/svg/image-download.svg"
                            className={'figure-img img-icon-square select-none ' + (isCommonDownloadDisabled ? 'img-btn-disabled' : 'img-btn')}
                            alt="グラフダウンロード"
                            data-tip="グラフダウンロード"
                            onClick={(e) => !isCommonDownloadDisabled && canvasDownloadHandler(NAME_CHART_COMMON_ID)}/>
                        </Col>
                        <Col xs={12}>
                          {nameChartCommon}
                        </Col>
                      </Row>
                    </Col>
                    {/* 固有印象評価結果 */}
                    <Col md={6}>
                      <Row>
                        <Col xs={9}>
                          <h6 className="title-sub">【{NAME_CHART_UNIQUE_TITLE}】</h6>
                        </Col>
                        <Col xs={3}>
                          <img
                            src="https://unicons.iconscout.com/release/v1.0.0/svg/table.svg"
                            className={'figure-img img-icon-square select-none ' + (isUniqueDownloadDisabled ? 'img-btn-disabled' : 'img-btn')}
                            alt="Excelダウンロード"
                            data-tip="Excelダウンロード"
                            onClick={(e) => !isUniqueDownloadDisabled && excelDownloadHandler(nameChartUniqueClassNumber)}/>
                          <img
                            src="https://unicons.iconscout.com/release/v1.0.0/svg/file-download-alt.svg"
                            className={'figure-img img-icon-square select-none ' + (isUniqueDownloadDisabled ? 'img-btn-disabled' : 'img-btn')}
                            alt="CSVダウンロード"
                            data-tip="CSVダウンロード"
                            onClick={(e) => !isUniqueDownloadDisabled && csvDownloadHandler(nameChartUniqueClassNumber)}/>
                          <img
                            id="name-chart-unique-download"
                            src="https://unicons.iconscout.com/release/v1.0.0/svg/image-download.svg"
                            className={'figure-img img-icon-square select-none ' + (isUniqueDownloadDisabled ? 'img-btn-disabled' : 'img-btn')}
                            alt="グラフダウンロード"
                            data-tip="グラフダウンロード"
                            onClick={(e) => !isUniqueDownloadDisabled && canvasDownloadHandler(NAME_CHART_UNIQUE_ID)}/>
                        </Col>
                        <Col xs={12}>
                          {nameChartUnique}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  {/* row name-summary */}
                  {/* <div className="row">
                    <div className="col-10">
                      <h6 className="title-sub">【ひとこと評価】</h6>
                    </div>
                    <div className="col-1">
                      <img id="name-summary-copy" src="https://unicons.iconscout.com/release/v1.0.0/svg/copy.svg" className="figure-img img-icon-square img-btn select-none" alt="クリップボードにコピー" data-tip="クリップボードにコピー" />
                    </div>
                    <div className="col-1">
                      <img id="name-summary-download" src="https://unicons.iconscout.com/release/v1.0.0/svg/file-download.svg" className="figure-img img-icon-square img-btn select-none" alt="ダウンロード" data-tip="ダウンロード" />
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <textarea id="name-summary" className="form-control" placeholder="ひとこと評価がここに表示されます" readOnly={true} autoComplete="off"></textarea>
                      </div>
                    </div>
                  </div> */}
                  {/* /row name-summary */}
                </div>
              </Tab>
              <Tab eventKey={HISTORY_TAB_KEY} title="評価履歴">
                <div className="p-3">
                  <EvaluationHistory activeTabKey={activeTabKey}/>
                </div>
              </Tab>
              <Tab eventKey={FAQ_TAB_KEY} title="FAQ">
                <div className="p-3">
                  <EvaluationFAQ/>
                </div>
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </Container>
    </div>
    // end of className="Evaluation"
  );
}
