import { classes as NAME_CLASSES_DICTIONARY } from '@/data/classes.json';

export const NameClassUtil = {
  /**
   * nameClassNumberに合致するnameClassを返す.
   * @param {Number} nameClassNumber
   * @returns {Object} {number: number, name: string, scales: [{id: number, name: string, label: string}]}.
   */
  getNameClass(nameClassNumber) {
    const num = Number(nameClassNumber);
    return NAME_CLASSES_DICTIONARY.find(nameClass => {
      return nameClass.number === num;
    });
  }
};
