import Papa from 'papaparse';

const config = {
  delimiter: ',', // 区切り文字
  header: true, // キーをヘッダーとして扱う
  newline: '\r\n', // 改行
};

export const CsvUtil = {
  generateCsvWithFieldsAndData(fields, data) {
    return Papa.unparse({
      fields: fields,
      data: data
    }, config);
  },
  createCsvDownloadLink(fileName, fields, data) {
    const csvData = new Blob(
      [this.generateCsvWithFieldsAndData(fields, data)],
      {type: 'text/csv;charset=utf-8;'}
    );

    let csvURL;
    if(navigator.msSaveBlob) {
      csvURL = navigator.msSaveBlob(csvData, fileName);
    } else {
      csvURL = window.URL.createObjectURL(csvData);
    }

    const a = document.createElement('a');
    a.href = csvURL;
    a.download = fileName;
    return a;
  }
};
