import React from 'react';

import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

// import aws amplify + auth
import Amplify, { I18n } from 'aws-amplify';
import { ConfirmSignIn, ConfirmSignUp, ForgotPassword, Loading, RequireNewPassword, SignIn, VerifyContact, withAuthenticator } from 'aws-amplify-react';
// import resources
import './App.scss';
import { CustomHeader } from '@/components/organisms/header/CustomHeader';
import ServiceIndex from '@/ServiceIndex';
import Evaluation from './Evaluation';
import ScrollToTop from './util/ScrollToTop';
import { auth as authVocabulariesJa } from './I18n/ja.json';

// Get the aws resources configuration parameters
import awsconfig from './aws-exports';
Amplify.configure(awsconfig);

// const
// const hapinaFederatedConfig = {
//   // GCP>APIとサービス>認証情報 の設定を参照
//   // TODO: OAuth同意画面の問い合わせ先を開発者直から窓口に変更する
//   google_client_id: "512480096225-ompmjcibluv368gralmrsfhgv492fdqv.apps.googleusercontent.com"
// }

// website structure
function App() {
  return (
    <Router>
      <ScrollToTop>
        <CustomHeader/>
        <Switch>
          <Route path="/" exact component={ServiceIndex} />
          <Route path="/evaluation/" component={Evaluation} />
          {/* no match */}
          <Redirect to="/" />
        </Switch>
      </ScrollToTop>
    </Router>
  );
}

// auth Theme
const AuthTheme = {
  // AWS oAuthSignIn を hidden
  oAuthSignInButton: { 'display': 'none'},
  strike: { 'display': 'none'},
};

// auth I18n
I18n.setLanguage('ja');
I18n.putVocabularies(authVocabulariesJa);

export default withAuthenticator(App, {
  // Render a sign out button once logged in
  includeGreetings: true,
  // Show only certain components (各コンポーネントはimportが必要)
  authenticatorComponents: [
    <ConfirmSignIn/>,
    <ConfirmSignUp/>,
    <ForgotPassword/>,
    // <CustomHeader />,
    <Loading/>,
    <RequireNewPassword/>,
    // <SignIn federated={hapinaFederatedConfig}/>,
    <SignIn/>,
    <VerifyContact/>
  ],
  // display federation/social provider buttons
  federated: {},
  // customize the UI/styling
  theme: AuthTheme,
  signUpConfig: {
    hiddenDefaults: ['phone_number']
  }
});
