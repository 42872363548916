import axios from 'axios';

const instance = axios.create({
  baseURL: 'https://w9ijnmi6zk.execute-api.ap-northeast-1.amazonaws.com/dev',
  headers: {
    'Accept': 'application/json',
    'x-api-key': 'ZCtOPswPcm9xEyM2MtI754oRIJBNz5Qc1FwdUmei',
    'Content-Type': 'application/x-www-form-urlencoded'
  }
});

export default instance;
