import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Button, Modal, ModalBody, ModalFooter } from 'react-bootstrap';

import { StringValidator } from '@/validators/StringValidator';
import { NAME_FORM_INPUT_TITLE, NAME_FORM_SELECT_TITLE } from '@/Evaluation';

import { NameClassUtil } from '@/util/NameClassUtil';

/**
 * 評価の実行確認モーダル.
 */
const NameFormSubmitModal = (props) => {
  const {
    name,
    nameclassnumber,
    onCancel,
    onSubmit
  } = props;

  const [isEvaluatable, setIsEvaluatable] = useState(false);

  useEffect(() => {
    setIsEvaluatable(StringValidator.isKana(name));
  }, [name]);

  return(
    <Modal {...props} onHide={onCancel} centered>
      <Modal.Header>
        <Modal.Title>
          この条件で評価しますか？
        </Modal.Title>
      </Modal.Header>
      <ModalBody>
        {/* TODO: colで位置を揃える */}
        <p>【{NAME_FORM_INPUT_TITLE}】 : {isEvaluatable ? name : <span style={{color:'red'}}>入力してください</span>}</p>
        <p>【{NAME_FORM_SELECT_TITLE}】 : {NameClassUtil.getNameClass(nameclassnumber).name}</p>
        {/* TODO: 表示する評価尺度を示せるようにする */}
        <p>※評価に使われる尺度は、評価例からご確認ください</p>
      </ModalBody>
      <ModalFooter>
        <Button onClick={onCancel}>キャンセル</Button>
        <Button onClick={onSubmit} disabled={!isEvaluatable}>評価する</Button>
      </ModalFooter>
    </Modal>
  );
};

NameFormSubmitModal.propTypes = {
  name: PropTypes.string.isRequired,
  nameclassnumber: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default NameFormSubmitModal;
