import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Accordion, Card } from 'react-bootstrap';

import { faqs as FAQs } from '@/data/faqs';

export const FAQ_TAB_KEY = 'faq';

function renderFAQs() {
  try {
    return FAQs.map((faq, index) =>
      <Row key={index}>
        <h6 className="title-sub">【{faq.category}】</h6>
        <Col md={12}>
          <Accordion className="shadow">
            {renderItems(faq.items)}
          </Accordion>
        </Col>
      </Row>
    );
  } catch (error) {
    return (
      <Row>
        <Col>
          FAQの取得に失敗しました。
        </Col>
      </Row>
    );
  }

  function renderItems(items) {
    return items.map((item, index) =>
      <Card key={index}>
        <Accordion.Toggle as={Card.Header} eventKey={index}>
          {item.question}
        </Accordion.Toggle>
        <Accordion.Collapse eventKey={index}>
          <Card.Body>
            <Row>
              <Col md={12}>
                {item.answer}
              </Col>
              { !item.link ?
                ''
                :
                <Col md={12}>
                  <a href={item.link.url}>{item.link.title}</a>
                </Col>
              }
            </Row>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    );
  }
}

// TODO できれば export default で書きたい
export default function EvaluationFAQ(props) {
  return(
    <div className="EvaluationFAQ">
      <Container>
        {renderFAQs()}
      </Container>
    </div>
  );
}
