import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

// TODO: 履歴操作用のモジュール（分割対象）
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '@/graphql/queries';

import { Container, Row, Col, Table, Spinner } from 'react-bootstrap';
import { Pagination } from 'react-bootstrap';

// util
import { GraphScaleUtil } from '@/util/GraphScaleUtil';
import { NameClassUtil } from '@/util/NameClassUtil';
import { CsvUtil } from '@/util/CsvUtil';
import { ExcelUtil } from '@/util/ExcelUtil';

import { NAME_FORM_INPUT_TITLE, NAME_FORM_SELECT_TITLE } from '@/Evaluation';

import { commonClassNumber as NAME_CLASSES_NUMBER_COMMON } from '@/data/classes.json';

export const HISTORY_TAB_KEY = 'history';
const PAGE_ITEMS_NUM = 15;


// 検索履歴の取得
async function listEvaluationHistories(userId, dateTime) {
  let histories = [];
  // 日時指定なしの場合は全データ取得
  if(true || dateTime === ''){ // TODO: 日付指定の処理はいまのところ使わない.
    // TODO: graphqlではデフォルト（limit設定なし）で一度に10件までしか取得しない？ 全件取得or nextTokenを使って続きを取得する。
    let result = {};
    const limit = 300; // dynamoDB側のデータの確認件数 // TODO: どこかに定数として定義する.
    let nextToken = '';
    do {
      if(nextToken){
        result = await API.graphql(graphqlOperation(queries.itemsByUserId, {userId: userId, limit: limit, nextToken: nextToken, sortDirection: 'DESC'}));
      }else{
        result = await API.graphql(graphqlOperation(queries.itemsByUserId, {userId: userId, limit: limit, sortDirection: 'DESC'}));
      }
      histories = histories.concat(result.data.itemsByUserId.items);
    } while (nextToken === result.data.itemsByUserId.nextToken);
  } else {
    // TODO: 日付指定があった時も、条件に合致する全件取得ができるようにする.
    histories = await API.graphql(graphqlOperation(queries.itemsByUserId, {userId: userId, dateTime: {ge: dateTime}}));
  }
  return histories;
}

/**
 * CSVダウンロード
 */
const csvDownloadHandler = async (his, classNumber) => {
  const response = await API.graphql(graphqlOperation(queries.getEvaluationHistory, {id: his.id, userId: his.userId, dateTime: his.dateTime}));
  const history = response.data.getEvaluationHistory;

  const chart = classNumber === NAME_CLASSES_NUMBER_COMMON ?
    history.charts[0] : history.charts[1];
  const fileName = GraphScaleUtil.generateChartLabel(history.name, classNumber);

  const fields = chart.dataSets.map(data => data.scale);
  const data = chart.dataSets.map(data => data.value);

  // 分類を追加
  fields.unshift('分類');
  data.unshift(classNumber === NAME_CLASSES_NUMBER_COMMON ?
    '共通' : NameClassUtil.getNameClass(classNumber).name);
  // 商品別/サービス名を追加
  fields.unshift('商品名/サービス名');
  data.unshift(history.name);

  const aTag = CsvUtil.createCsvDownloadLink(fileName, fields, data);
  aTag.click();
};

/**
 * Excelダウンロード
 */
const excelDownloadHandler = async (his, classNumber) => {
  const response = await API.graphql(graphqlOperation(queries.getEvaluationHistory, {id: his.id, userId: his.userId, dateTime: his.dateTime}));
  const history = response.data.getEvaluationHistory;

  const chart = classNumber === NAME_CLASSES_NUMBER_COMMON ?
    history.charts[0] : history.charts[1];
  const fileName = GraphScaleUtil.generateChartLabel(history.name, classNumber);

  const headers = chart.dataSets.map(data => data.scale);
  const data = chart.dataSets.map(data => data.value);

  // 分類を追加
  headers.unshift('分類');
  data.unshift(Number(classNumber) === NAME_CLASSES_NUMBER_COMMON ?
    '共通' : NameClassUtil.getNameClass(classNumber).name);
  // 商品別/サービス名を追加
  headers.unshift('商品名/サービス名');
  data.unshift(history.name);

  const aTag = ExcelUtil.createExcelDownloadLink(fileName, headers, [data]);
  aTag.click();
};


const EvaluationHistory = (props) => {
  const {
    activeTabKey
  } = props;

  // name-evaluation-history
  const [IENamingHistories, setIENamingHistories] = useState([]);
  const [isLoading, setIsLoading] = useState([true]);
  const [currentPageNum, setCurrentPageNum] = useState(1);

  const pageList = useMemo(() => {
    if (IENamingHistories.length === 0) {
      return [];
    }
    if (IENamingHistories.length % PAGE_ITEMS_NUM === 0) {
      // 表示可能数で割って余りがない場合
      return [...Array(Math.floor(IENamingHistories.length / PAGE_ITEMS_NUM)).keys()].map(i => ++i);
    }
    return [...Array(Math.floor(IENamingHistories.length / PAGE_ITEMS_NUM)+ 1).keys()].map(i => ++i);
  }, [IENamingHistories]);

  useEffect(() => {
    if (activeTabKey !== HISTORY_TAB_KEY) {
      // アクティブの場合以外は処理しない
      return;
    }
    setIsLoading(true);
    // "sample"(評価例)は履歴として取得されない.
    listEvaluationHistories('userId', '')
      .then((histories) => {
        setIENamingHistories(histories);
      }).finally(() => {
        setIsLoading(false);
      });
  }, [activeTabKey]);

  const renderHistories = () => {
    const firstElementIndex = (currentPageNum - 1) * PAGE_ITEMS_NUM;
    const lastElementIndex = (currentPageNum * PAGE_ITEMS_NUM) - 1;

    if (!IENamingHistories || IENamingHistories.length === 0) {
      return (
        <tr>
          <td colSpan={3}>評価履歴はありません。</td>
        </tr>
      );
    } else {
      return IENamingHistories.slice(firstElementIndex, lastElementIndex).map((history) => {
        const isCommonDownloadDisabled = history.charts.length < 1;
        const isUniqueDownloadDisabled = history.charts.length < 2;

        return (
          <tr key={history.id}>
            <td>{history.dateTime}</td>
            <td>{history.name}</td>
            <td>{history.charts[history.charts.length - 1].class}</td>
            <td>
              <span>
                共通:
                <img
                  src="https://unicons.iconscout.com/release/v1.0.0/svg/table.svg"
                  className={'figure-img img-icon-square select-none ' + (isCommonDownloadDisabled ? 'img-btn-disabled' : 'img-btn')}
                  alt="Excelダウンロード"
                  data-tip="Excelダウンロード"
                  onClick={(e) => !isCommonDownloadDisabled && excelDownloadHandler(history, history.charts[0].classNumber)}/>
                <img
                  src="https://unicons.iconscout.com/release/v1.0.0/svg/file-download-alt.svg"
                  className={'figure-img img-icon-square select-none ' + (isCommonDownloadDisabled ? 'img-btn-disabled' : 'img-btn')}
                  alt="CSVダウンロード"
                  data-tip="CSVダウンロード"
                  onClick={(e) => !isCommonDownloadDisabled && csvDownloadHandler(history, history.charts[0].classNumber)}/>
              </span>
              <span className="ml-2">
                分類別:
                <img
                  src="https://unicons.iconscout.com/release/v1.0.0/svg/table.svg"
                  className={'figure-img img-icon-square select-none ' + (isUniqueDownloadDisabled ? 'img-btn-disabled' : 'img-btn')}
                  alt="Excelダウンロード"
                  data-tip="Excelダウンロード"
                  onClick={(e) => !isUniqueDownloadDisabled && excelDownloadHandler(history, history.charts[1].classNumber)}/>
                <img
                  src="https://unicons.iconscout.com/release/v1.0.0/svg/file-download-alt.svg"
                  className={'figure-img img-icon-square select-none ' + (isUniqueDownloadDisabled ? 'img-btn-disabled' : 'img-btn')}
                  alt="CSVダウンロード"
                  data-tip="CSVダウンロード"
                  onClick={(e) => !isUniqueDownloadDisabled && csvDownloadHandler(history, history.charts[1].classNumber)}/>
              </span>
            </td>
          </tr>
        );
      });
    }
  };

  return (
    <Container>
      <Row>
        <h6 className="title-sub">【評価履歴】</h6>
        {
          isLoading &&
            <Col xs={12} className="text-center">
              <Spinner
                animation="border"
                role="status"
                variant="primary"
                className="mt-5"
                aria-hidden="true"/>
            </Col>
        }
        {
          !isLoading &&
            <Col lg={12}>
              <Table bordered borderless hover responsive striped size="sm" className="border text-nowrap">
                <thead className="border">
                  <tr>
                    <th>日時</th>
                    <th>{NAME_FORM_INPUT_TITLE}</th>
                    <th>{NAME_FORM_SELECT_TITLE}</th>
                    <th>ダウンロード</th>
                  </tr>
                </thead>
                <tbody>
                  {renderHistories()}
                </tbody>
              </Table>
              <Pagination className="mt-2">
                {
                  pageList.map(pageNum => {
                    return (
                      <Pagination.Item
                        key={pageNum}
                        active={pageNum === currentPageNum}
                        onClick={(e) => setCurrentPageNum(pageNum)}>
                        {pageNum}
                      </Pagination.Item>
                    );
                  })
                }
              </Pagination>
            </Col>
        }
      </Row>
    </Container>
  );
};

EvaluationHistory.propTypes = {
  activeTabKey: PropTypes.string.isRequired
};

export default EvaluationHistory;
