// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_content_delivery_bucket": "hapina-hostingbucket-develop",
    "aws_content_delivery_bucket_region": "ap-northeast-1",
    "aws_content_delivery_url": "https://d3gk62s2lldbuf.cloudfront.net",
    "aws_cognito_identity_pool_id": "ap-northeast-1:3e15d31d-7ba0-4105-935d-22d22804368e",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_kYNxXMx70",
    "aws_user_pools_web_client_id": "6cvdttqkg0n7thb5qcqmvd3bmt",
    "oauth": {
        "domain": "hapina-develop.auth.ap-northeast-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://d3gk62s2lldbuf.cloudfront.net",
        "redirectSignOut": "https://d3gk62s2lldbuf.cloudfront.net",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_appsync_graphqlEndpoint": "https://ys26kw6wdzatbosqngdigqimry.appsync-api.ap-northeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-northeast-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
