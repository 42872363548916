import XLSX from 'xlsx';

export const ExcelUtil = {
  // dataは[[row1],[row2],...]で渡す
  createExcelDownloadLink(fileName, headers, data) {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet([
      headers,
      ...data
    ]);
    // workbookにworksheetを追加（file名とタブ名を同じにする）
    XLSX.utils.book_append_sheet(workbook, worksheet, fileName);

    // データを書き出し
    const wData = XLSX.write(workbook, {
      bookType: 'xlsx',
      bookSST: false,
      type: 'binary'
    });

    // string to array buffer
    const stringToArrayBuffer = (s) => {
      const buf = new ArrayBuffer(s.length);
      const view = new Uint8Array(buf);
      for (var i = 0; i !== s.length; ++i) {
        view[i] = s.charCodeAt(i) & 0xFF;
      }
      return buf;
    };

    const excelData = new Blob(
      [stringToArrayBuffer(wData)],
      {type: 'application/octet-stream'}
    );

    let excelURL;
    if(navigator.msSaveBlob) {
      excelURL = navigator.msSaveBlob(excelData, fileName + '.xlsx');
    } else {
      excelURL = window.URL.createObjectURL(excelData);
    }

    const a = document.createElement('a');
    a.href = excelURL;
    a.download = fileName + '.xlsx';
    return a;
  }
};
