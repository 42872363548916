/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getEvaluationHistory = `query GetEvaluationHistory($id: ID!, $userId: String!, $dateTime: String!) {
  getEvaluationHistory(id: $id, userId: $userId, dateTime: $dateTime) {
    id
    userId
    dateTime
    name
    charts {
      classNumber
      class
      dataSets {
        scale
        value
      }
    }
  }
}
`;
export const listEvaluationHistorys = `query ListEvaluationHistorys(
  $id: ID
  $userIdDateTime: ModelEvaluationHistoryPrimaryCompositeKeyConditionInput
  $filter: ModelEvaluationHistoryFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listEvaluationHistorys(
    id: $id
    userIdDateTime: $userIdDateTime
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      userId
      dateTime
      name
      charts {
        classNumber
        class
      }
    }
    nextToken
  }
}
`;
export const itemsByUserId = `query ItemsByUserId(
  $userId: String
  $dateTime: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelEvaluationHistoryFilterInput
  $limit: Int
  $nextToken: String
) {
  itemsByUserId(
    userId: $userId
    dateTime: $dateTime
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userId
      dateTime
      name
      charts {
        classNumber
        class
      }
    }
    nextToken
  }
}
`;
