import _ from 'lodash';

const REGEX_KANA = /^[ぁ-ゖァ-ー]+$/;

export const StringValidator = {
  isKana(value) {
    if (_.isString(value)) {
      // ひらがなカタカナのみ、空文字不可
      return REGEX_KANA.test(value);
    }
    return false;
  }
};
