import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { Badge, Button, Jumbotron } from 'react-bootstrap'; // for renderServices
import { Accordion, Card } from 'react-bootstrap'; // for renderNews

// import html/css
import './ServiceIndex.scss';

// import resources
import logo_Hapina from './images/logo/Hapina/logo_transparent.png';
import logo_Impression_Evaluator from './images/logo/ImpressionEvaluator/logo_transparent.png';
import logo_IE_Naming from './images/logo/ImpressionEvaluator/Naming/logo_transparent.png';
import logo_IE_Color from './images/logo/ImpressionEvaluator/Color/logo_transparent.png';
import logo_IE_API from './images/logo/ImpressionEvaluator/API/logo_transparent.png';
import { news } from './data/news';

function renderNews(news) {
  return (
    <Accordion className="shadow">
      {renderAccordionCards(news)}
    </Accordion>
  );

  /**
   * Accordion内のCardsをnewsの数だけ描画する.
   * @param {*} news
   */
  function renderAccordionCards(news) {
    return news.map((news, index) =>
      <Card key={index}>
        <Accordion.Toggle as={Card.Header} eventKey={index}>
          {/* TODO: news.dateが新しければnewバッジをつける */}
          <Row className="p-0">
            <Col md={12}>
              {renderNewBadge(news.date)} [ {news.date} ]
            </Col>
            <Col md={12}>
              {news.title}
            </Col>
          </Row>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey={index}>
          <Card.Body>
            <Row>
              <Col md={12}>
                {news.value}
                { !news.link ?
                ''
                :
                <Col md={12}>
                  <a href={news.link.url}>{news.link.title}</a>
                </Col>
                }
              </Col>
            </Row>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    );

    /**
     * newsDateが現在の日付の前月までなら、"new"のバッジをつける.
     * @param {*} newsDate
     */
    function renderNewBadge(newsDate) {
      const newsMonth = newsDate.substring(5, newsDate.lastIndexOf('/'));
      let currentMonth = (new Date()).getMonth() + 1;
      if(newsMonth == currentMonth || newsMonth % 12 + 1 == currentMonth){
        return (
          <Badge pill variant="info">
            New!
          </Badge>
        );
      }
      return;
    }
  }
}

export default function ServiceIndex(props) {
  // console.log(props.authData);
  return (
    <div className="ServiceIndex">
      <Container>
        <Row className="title">
          <Col xl={12}>
            <img src={logo_Hapina} className="logo-main" alt="logo_Hapina" />
            {/* <img src={logo_Impression_Evaluator} className="logo-main" alt="logo_Impression_Evaluator" /> */}
            {/* <h1 className="service-title">Hapina (ハピナ) - 感性評価AI - サービス一覧</h1> */}
          </Col>
        </Row>
        <Row className="services">
          <h4 className="title-sub">【サービス一覧】</h4>
          <Col xl={12}>
            {/* TODO: メソッド動作に問題があるため実装を見送り */}
            {/* {renderServices(services)} */}
            <Jumbotron id="ImpressionEvaluator">
              <Row className="py-0 align-items-center">
                <Col md={4} className="text-center">
                  <img src={logo_Impression_Evaluator} className="logo-service" alt="logo_Impression_Evaluator" />
                </Col>
                <Col md={8}>
                  <Button variant="light" size="lg" block className="border shadow btn-service-sub">
                    <Link to="/evaluation/">
                      <Row className="py-0 align-items-center">
                        <Col md={6} className="text-center">
                          <img src={logo_IE_Naming} className="logo-service-sub" alt="logo_IE_Naming" />
                        </Col>
                        <Col md={6} className="text-left">
                          <label>名前の印象評価</label>
                        </Col>
                      </Row>
                      {/* <img src={logo_Impression_Evaluator} className="logo-service" alt="logo_Impression_Evaluator" />
                      <label>KANSEI - Impression Evaluator</label> */}
                    </Link>
                  </Button>
                  <Button variant="light" size="lg" block disabled className="border shadow btn-service-sub">
                    <Link to="">
                      <Row className="py-0 align-items-center">
                        <Col md={6} className="text-center">
                          <img src={logo_IE_Color} className="logo-service-sub" alt="logo_IE_Color" />
                        </Col>
                        <Col md={6} className="text-left">
                          <label>Coming soon ...</label>
                        </Col>
                      </Row>
                    </Link>
                  </Button>
                  <Button variant="light" size="lg" block disabled className="border shadow btn-service-sub">
                    <Link to="">
                      <Row className="py-0 align-items-center">
                        <Col md={6} className="text-center">
                          <img src={logo_IE_API} className="logo-service-sub" alt="logo_IE_API" />
                        </Col>
                        <Col md={6} className="text-left">
                          <label>Coming soon ...</label>
                        </Col>
                      </Row>
                    </Link>
                  </Button>
                </Col>
              </Row>
            </Jumbotron>
          </Col>
        </Row>
        <Row className="news">
          <h4 className="title-sub">【News】</h4>
          <Col xl={12}>
            {renderNews(news)}
          </Col>
        </Row>
      </Container>
    </div>
  );
}
