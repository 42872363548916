import React from 'react';
import './CustomHeader.scss';

// resources
import HapinaLogo from '@/images/logo/Hapina/logo_transparent.png';

import { Greetings } from 'aws-amplify-react';
import { Link } from 'react-router-dom';

export const CustomHeader = () => {
  return (
    <header id="header">
      <Link to="/">
        <img id="header-logo" src={HapinaLogo} alt="header logo" />
      </Link>
      <Greetings
        inGreeting={(username) => 'ようこそ ' + username + ' さん'}
        outGreeting="サインインして Hapina をご利用ください"/>
    </header>
  );
};
