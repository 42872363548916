import React from 'react';
import PropTypes from 'prop-types';

import { Chart, HorizontalBar } from 'react-chartjs-2';

import { GraphScaleUtil } from '@/util/GraphScaleUtil';

const EvaluationBarChart = (props) => {
  const {
    chartId,
    dataSets,
    nameClassNumber
  } = props;

  Chart.plugins.register({
    beforeDraw: function(ch) {
      var ctx = ch.chart.ctx;
      ctx.fillStyle = 'rgba(250, 250, 250, 1)';
      ctx.fillRect(0, 0, ch.chart.width, ch.chart.height);
    }
  });

  return (
    <HorizontalBar
      id={chartId}
      data={{
        labels: GraphScaleUtil.getNameClassSortedScaleLabels(nameClassNumber),
        datasets: dataSets
      }}
      width={500}
      height={500}
      options={
        {
          maintainAspectRatio: false,
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                }
              },
            ],
            xAxes: [
              {
                ticks: {
                  min: -1,
                  max: 1,
                  stepSize: 0.3
                },
              }
            ]
          },
          // TODO: ツールチップでは、TitleにSignedScaleLabel、labelに「~0.3:印象なし/~0.6:印象あり/0.6~:強い印象あり」と表示する
          // tooltips: {
          //   callbacks: {
          //     title: function(tooltipItem, data){
          //       // title内ではtooltipItemの値（カーソルが重なった箇所のデータ）を表示する場合は`tooltip[0]`のよう要素を指定することに注意!!
          //       return tooltipItem[0].xLabel + "hoge";
          //     },
          //     label: function(tooltipItem, data){
          //       return [tooltipItem.yLabel + "huga"]; //リストで記述すると改行される
          //     }
          //   }
          // },
          // chartjs-plugin-datalabels の設定（datasets毎にも設定可能）
          plugins: {
            datalabels: {
              display: true,
              anchor: 'center',
              align: 'center',
              clamp: true,
              formatter(value, context) {
                const isPositiveValue = (0 < value);
                if (value === null || value === 0) {
                  return '';
                }else if(-0.3 < value && value < 0.3){
                  return '';
                }
                return GraphScaleUtil.getNameClassSortedScaleSignedLabel(nameClassNumber, context.dataIndex, isPositiveValue);
              }
            }
          }
        }
      }
    />
  );
};

EvaluationBarChart.propTypes = {
  chartId: PropTypes.string.isRequired,
  dataSets: PropTypes.array.isRequired,
  nameClassNumber: PropTypes.number.isRequired
};

export default EvaluationBarChart;
