
import moment from 'moment-timezone';

const DATE_TIME_FORMAT = 'YYYY/MM/DD HH:mm:ss';

export const DateTimeUtil = {
  getCurrentTimeInJST() {
    return moment.tz('Asia/Tokyo').format(DATE_TIME_FORMAT);
  }
};
