/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createEvaluationHistory = `mutation CreateEvaluationHistory($input: CreateEvaluationHistoryInput!) {
  createEvaluationHistory(input: $input) {
    id
    userId
    dateTime
    name
    charts {
      classNumber
      class
      dataSets {
        scale
        value
      }
    }
  }
}
`;
export const updateEvaluationHistory = `mutation UpdateEvaluationHistory($input: UpdateEvaluationHistoryInput!) {
  updateEvaluationHistory(input: $input) {
    id
    userId
    dateTime
    name
    charts {
      classNumber
      class
      dataSets {
        scale
        value
      }
    }
  }
}
`;
export const deleteEvaluationHistory = `mutation DeleteEvaluationHistory($input: DeleteEvaluationHistoryInput!) {
  deleteEvaluationHistory(input: $input) {
    id
    userId
    dateTime
    name
    charts {
      classNumber
      class
      dataSets {
        scale
        value
      }
    }
  }
}
`;
